export const endUserTermsAndConditionsText = `
_These End User Terms and Conditions were last revised on 13th June 2024._

## 1. General
 - (a) &emsp; The Real Estate Agency Hub (**Software**) is a web-based workflow tool and software platform provided as a service by Stafflink Pty Ltd ABN 52 626 030 742 (**Stafflink**, **we, our** or **us**). By accessing or using the Software, you signify your agreement to be bound by these end user terms (**Terms**).

 - (b) &emsp; If you do not accept these Terms, you must immediately cease accessing or using the Software.

 - (c) &emsp; When we say “You” or “Your”, we mean you and the entity or organisation that has authorised you to access and use the Software on its behalf.

 - (d) &emsp; These Terms govern your use of the Software and related services offered by Stafflink.

 - (e) &emsp; We may review and amend these Terms from time to time, by publishing the new version on the Software or on our website at stafflink.com.au. Your continued use of the Software after we post any changes to these Terms will constitute your agreement to those changes effective from that date.

## 2. Access to the Software
   1) Provided that you comply with these Terms, we grant you a non-exclusive, non-transferable, limited right to access and use the Software in accordance with these Terms.
   1) The Software may contain links to other websites and content created by people other than us. We do not control, endorse, sponsor, approve or accept any responsibility or liability for the content available on any linked website.
## 3. Your conduct and obligations
   1) You agree:
      1) to provide and maintain accurate and up-to-date information pertaining to your full name, email address, and mobile phone (where applicable);
      1) to maintain the confidentiality of your account credentials and protect your account from unauthorised access or use;
      1) to notify us immediately if you become aware your account credentials have been lost, shared, compromised or misappropriated.
      1) to comply with these Terms all applicable laws and regulations; and
      1) to use the Software in good faith.
   1) In accessing or using the Software you must not:
      1) share user credentials with others;
      1) use the Software to defame, spam or harass any person or to distribute, view or create any material that may be pornographic, defamatory, offensive, obscene, illegal or unlawful;
      1) use the Software in a way that may infringe our intellectual property rights or those of a third party;
      1) reproduce any part of the Software for sale or incorporation into any other product or service intended for sale;
      1) attempt to gain unauthorised access to any materials, documents or code other than which you have been given express permission to access, or to the computer system on which the Software is hosted;
      1) transmit or upload to the Software  any files, code or programs that may damage or corrupt the IT infrastructure, devices, data or software of Stafflink or any third party;
      1) use or download content from the Software by way of automated software;
      1) engage in any illegal or unlawful activity, including any fraudulent use of the Software or our products;
      1) engage in any activity that is otherwise deemed by us to be contrary to the way our Software or products are designed or permitted to be used;
      1) engage in any activity that otherwise adversely affects other users' use of, or access to, our Software; or
      1) engage in any activity otherwise deemed inappropriate by Stafflink.
## 4. Intellectual Property Rights
   1) You acknowledge that we or our licensors own all legal rights, title and interest in and to the Software, and nothing in these Terms constitutes a transfer or assignment of any intellectual property rights by us.
   1) You must not modify, adapt, translate, prepare derivative works from, decompile, reverse engineer, disassemble, scrape, or interface (without our permission), or otherwise attempt to derive source code or data from any component of the Software, or create or attempt to create a substitute or similar service or product through use of or access to the Software.
   1) You must not use, register or attempt to register any trade marks or domain names that are derived from, substantially identical or deceptively similar to our own or those otherwise used on the Software.
   1) You agree that Stafflink may collect, use and share technical data, usage statistics and related information (**Data**) that is gathered periodically to facilitate the provision of updates, address enquiries and conduct other activities related to the Software and third parties may additionally monitor, use and store Data to improve the Software.
   1) You grant to us a perpetual, non-exclusive, royalty-free, irrevocable and worldwide licence to use, copy, modify, adapt, publish, transmit, broadcast or sub-license any material, information or data (including intellectual property) that you upload, or arrange to have uploaded, to the Software in any way. You acknowledge and agree that this licence will survive termination of these Terms. You warrant to us that you have all right, title and authority to grant this licence.
## 5. Third Party Providers
   1) In this clause [5](#_ref152581556), **Third Party Providers** means third parties whom Stafflink may engage from time to time to perform or provide a service in connection with the Software including hosting, data warehousing, email, security services, telecommunications service providers, and analytics service providers, or to provide a component or service required for a feature of the Software.
   1) You acknowledge and agree:
      1) Stafflink may engage Third Party Providers in the provision of the Software or our services.
      1) to comply with Third Party Providers' licence or other terms and conditions as notified to you.
## 6. Availability and Suspension
   1) In this clause [6](#_ref152747463),
      1) **Third Party Providers** means third parties whom Stafflink may engage from time to time to perform or provide a service in connection with the Software including hosting, data warehousing, email, security services, telecommunications service providers, and analytics service providers, or to provide a component or service required for a feature of the Software.
      1) **Exceptional Circumstance** means a circumstance beyond the reasonable control of the parties which results in a party being unable to observe or perform on time an obligation under these Terms.  Such circumstances include:
         1) adverse changes in government regulations;
         1) any disaster or act of God, lightning strikes, atmospheric disturbances, earthquakes, floods, storms, explosions, fires and any natural disaster;
         1) acts of war, acts of public enemies, terrorism, riots, civil commotion, malicious damage, sabotage and revolution, cyber attacks, viruses or malware, data loss as a result of the actions of a third party;
         1) strikes or industrial disputes; and
         1) acts or omissions of any third party network providers (such as internet, telephony or power provider).
   1) Except as otherwise agreed, Stafflink makes no warranty, and has no obligation to ensure that, the Software is:
      1) available for use at all times or at any given time;
      1) compatible with the third party software or chosen internet browser you choose to access the Software; or
      1) error-free, or free from viruses, bugs or malicious computer codes.
   1) Stafflink and our Third Party Providers do not, and are not obligated to provide, any technical or other support for the provision of the Software to you.
   1) We may temporarily suspend (in part or in whole), and without prior notice to you, your access to the Software if:
      1) there is a malfunction, fault or breakdown of any of our (or our contractors) equipment or if we (or our contractor) needs to do any repairs, maintenance or service on any part of the Software;
      1) we are required by law to do so;
      1) an event of Exceptional Circumstance occurs, which affects or may affect our ability to provide the Software;
      1) the subscription fees payable in connection with your account have not been paid in accordance with the applicable payment terms;
      1) if someone claims the Software (or our provision of the Software) infringes the intellectual property rights of any person;
      1) if someone brings a claim that exposes us to liability or prosecution for an offence or liability to a statutory prosecution; or
      1) if we determine or reasonably suspect you are in breach of these Terms.

A suspension for any of these reasons will not affect any right which accrue prior to, or after, suspension of our obligations under these Terms.

1) We may, without notice to you, modify or remove any content on the Software if we determine or reasonably suspect the content:
   1) is in breach of these Terms;
   1) is pornographic, defamatory, offensive, obscene, illegal or unlawful;
   1) infringes Stafflink's or any third party's intellectual property rights;
   1) contravenes any applicable laws, rules or regulations;
   1) is damaging to the IT infrastructure, devices, data or software of Stafflink; or
   1) is otherwise deemed inappropriate by Stafflink.
1) You acknowledge and agree that:
   1) access to the Software may occasionally be limited due to scheduled maintenance; and
   1) access to the Software is reliant upon various factors outside our control, including, without limitation, events of Exceptional Circumstance, your internet service provider, telecommunications provider or equipment used to access the Software.

While we will use reasonable endeavours to ensure you have continuous access to the Software, we will not be liable to you or any other person for any claim or to any other extent for loss or damage caused by any scheduled maintenance or such factors.

1) You will have no claim against us in respect of loss of access or functionality to the Software.
## 7. Privacy
   1) In this clause [7](#_ref152581571):
      1) **Privacy Act** means the *Privacy Act 1988* (Cth); and
      1) **Personal Information** means the same as “personal information” in the Privacy Act.
   1) We maintain a privacy policy that sets out how we handle your Personal Information. You should read our privacy policy at [https://stafflink.com.au/privacy-policy/](). By accepting these Terms, you will be taken to have read and accepted our privacy policy.
   1) Our privacy policy is a non-contractual document prescribed by the Privacy Act. It does not impose any contractual obligations on us, and we disclaim any such contractual obligations.
   1) The Privacy Act requires us to take such steps as are reasonable in the circumstances to ensure that any recipients of personal information outside of Australia do not breach the privacy principles contained within Privacy Act.
   1) You consent, acknowledge and agree that:
      1) any Personal Information disclosed to us via the use of the Software may be disclosed to our associated entities, third party service providers or contractors, and/or stored on infrastructure used by us, outside of Australia; and
      1) by continuing to use the Software, you expressly agree and consent to the disclosure of any personal information outside of Australia in the manner permitted by this clause.
   1) In providing this consent you understand and acknowledge that countries outside Australia do not always have the same privacy protection obligations as Australia in relation to personal information. You further agree that Australian Privacy Principle 8.1 does not apply to disclosures referred to in clause [7(e)](#_ref152581013).
## 8. Limitation of liability
   1) Stafflink is not liable for any virus, malware, trojan or similar malicious software program or code infecting your software or hardware as a result of your access to or use of the Software.
   1) To the fullest extent permitted by law:
      1) Stafflink excludes all liability in respect of any loss of data, interruption of business or any indirect or consequential loss, loss of profits, loss of opportunity or incidental damages; and
      1) Stafflink excludes all warranties and representations (express and implied) unless otherwise stated to the contrary in these Terms.
   1) To the fullest extent permitted by law, in no event will Stafflink's total aggregate liability (whether in contract, tort (including negligence), or statute) in connection with these Terms exceed the greater of A$10 or the amount you paid to us for your access to or use of the Software in the three months before our liability arose.
## 8. Indemnity

You agree to indemnify Stafflink, its officers and employees or agents from and against any loss, claim, liability, cost or expense incurred by Stafflink (including consequential loss) in respect of a claim arising from or in any way related to:

1) your breach of these Terms;
1) your breach of Third Party Provider's licence or other terms and conditions as notified to you;
1) your infringement or alleged infringement of Stafflink's or a third party's intellectual property rights;
1) your use of the Software or of any output created or delivered from your use of the Software; or
1) your violation of applicable laws, rules or regulations in connection with your use of the Software.
## 10. Miscellaneous
   1) These Terms are governed by and construed in accordance with the laws of Queensland, Australia. Each party irrevocably submits to the non-exclusive jurisdiction of the courts of Queensland.
   1) You must not assign, transfer or novate any of your rights or obligations (including your account) under or relating to these Terms.
   1) We may assign, transfer or novate any of its benefits, rights or obligations under or relating to these Terms by notice in writing to you.
   1) If a provision of these Terms is illegal, invalid, unenforceable or void in a jurisdiction it is severed for that jurisdiction and the remainder of these Terms have full force and effect and the validity or enforceability of that provision in any other jurisdiction is not affected.
`

export default endUserTermsAndConditionsText
